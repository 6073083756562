<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="checkPermission('Validation de paie commerciaux Th')"
        >
          <router-link class="menu-item" to="/commerciaux/validation-paie">
            Validation de paie
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="checkPermission('Validation des AAF commerciaux')"
        >
          <router-link class="menu-item" to="/commerciaux/validation-aff">
            Validation des AAF
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="checkPermission('Suivi de paiement des Indépendants Th')"
        >
          <router-link
            class="menu-item"
            to="/commerciaux/suivi-paiement-independant"
          >
            Suivi de paiement des Indépendants
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="checkPermission('Récapitulatif Commemciaux par filiale Th')"
        >
          <router-link
            class="menu-item"
            to="/commerciaux/recap-commerciaux-par-filiale"
          >
            Récapitulatif Commemciaux par filiale
          </router-link>
        </div>
        <div class="menu-link" v-if="checkPermission('Suivi des acomptes')">
          <router-link class="menu-item" to="/commerciaux/suivi-acomptes">
            Suivi des acomptes
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'checkPermission'])
  }
};
</script>

<style scoped lang="scss"></style>
